const data = {
  state: {
    dashboard1: {
      place: null,
      events: [],
      counters: {
        n_events: 0,
        n_events_expected: 0,
        n_articles: 0
      },
      indexReal: 0,
      indexPerceived: 0
    },
    dashboard2: {
      'Competitive Awareness': 0,
      'Consapevolezza Brand': 0,
      SentimentCC: 0,
      SentimentVersus: 0,
      dates: {
        'Competitive Awareness': {},
        'Consapevolezza Brand': {},
        SentimentCC: {},
        SentimentVersus: {}
      }
    },
    dashboard3: {
      dashboard3_1: {
        training: {},
        tooltip: {}
      },
      dashboard3_2: {
        predictions: {}
      },
      dashboard3_3: {
        alerts: {}
      }
    }
  },
  mutations: {
    setPlace1(state, place) {
      state.dashboard1.place = place;
    },
    setData1(state, data) {
      state.dashboard1.indexReal = parseFloat(data.indice_sicurezza_reale?.toFixed(2)) || 0;
      state.dashboard1.indexPerceived = parseFloat(data.indice_sicurezza_percepita?.toFixed(2)) || 0;
      state.dashboard1.counters.n_articles = data.total_n_articles || 0;
      state.dashboard1.counters.n_events_expected = data.total_n_events_attesi || 0;
      state.dashboard1.counters.n_events = data.total_n_events || 0;
      state.dashboard1.events = data.events || [];
    },
    setData2(state, data) {
      state.dashboard2['Competitive Awareness'] =
        data['Competitive Awareness']?.avg_score || null;
      state.dashboard2['Consapevolezza Brand'] =
        data['Consapevolezza Brand']?.avg_score || null;
      state.dashboard2.SentimentCC = data.SentimentCC?.avg_score || null;
      state.dashboard2.SentimentVersus =
        data.SentimentVersus?.avg_score || null;
      state.dashboard2.dates['Consapevolezza Brand'] =
        data['Consapevolezza Brand']?.dates || null;
      state.dashboard2.dates['Competitive Awareness'] =
        data['Competitive Awareness']?.dates || null;
      state.dashboard2.dates.SentimentCC = data['SentimentCC']?.dates || null;
      state.dashboard2.dates.SentimentVersus =
        data['SentimentVersus']?.dates || null;
    },
    setData3(state, data) {
      state.dashboard3.dashboard3_1.training =
        data.dashboard3_1?.training || {};
      state.dashboard3.dashboard3_1.tooltip = data.dashboard3_1?.tooltip || {};
      state.dashboard3.dashboard3_2.predictions =
        data.dashboard3_2?.predictions || {};
      state.dashboard3.dashboard3_3.alerts = data.dashboard3_3 || {};
    },
    setInitialState(state) {
      state.dashboard1 = {
        place: null,
        events: [],
        counters: {
          n_events: 0,
          n_events_expected: 0,
          n_articles: 0
        },
        indexReal: 0,
        indexPerceived: 0
      };
      state.dashboard2 = {
        'Competitive Awareness': 0,
        'Consapevolezza Brand': 0,
        SentimentCC: 0,
        SentimentVersus: 0,
        dates: {
          'Competitive Awareness': {},
          'Consapevolezza Brand': {},
          SentimentCC: {},
          SentimentVersus: {}
        }
      };
      state.dashboard3 = {
        dashboard3_1: {
          training: {},
          tooltip: {}
        },
        dashboard3_2: {
          predictions: {}
        },
        dashboard3_3: {
          alerts: {}
        }
      };
    }
  },
  actions: {},
  getters: {
    place: (state) => state.dashboard1.place,
    events: (state) => state.dashboard1.events
  }
};

export default data;
