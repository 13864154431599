<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
#app {
  min-height: 100vh;
}

.customtooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.customtooltip .customtooltiptext {
  width: 250px;
  bottom: 125%;
  left: -125px;
  margin-left: 3px;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  background-color: #555;
  text-align: center;
  position: absolute;
  font-size: small;
  transition: opacity 0.3s;
  visibility: hidden;
  text-transform: initial;
  opacity: 0;
  z-index: 1;
}

.customtooltip .customtooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.customtooltip:hover .customtooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
