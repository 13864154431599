<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :logo="
        user === 'demo_arma'
          ? 'img/theme/logo_carabinieri.png'
          : 'img/theme/logo.png'
      "
      :background-color="sidebarBackground"
      short-title="SicurIA"
      title="SicurIA"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Indici di Sicurezza',
            icon: 'ni ni-bullet-list-67 text-black',
            path: '/dashboard1'
          }"
        />
        <sidebar-item
          v-if="user === 'demo_arma'"
          :link="{
            name: 'Percezione dell\'Arma',
            icon: 'ni ni-bullet-list-67 text-black',
            path: '/dashboard2'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Scenari',
            icon: 'ni ni-bullet-list-67 text-black',
            path: '/dashboard3'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Guida',
            icon: 'ni ni-book-bookmark text-black',
            path:
              user === 'demo_arma'
                ? '/files/Manuale_SicurIA.pdf'
                : '/files/Manuale_SicurIA_enti_pubblici.pdf',
            target: '_blank'
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';

export default {
  components: {
    DashboardNavbar,
    ContentFooter
  },
  data() {
    return {
      user: '',
      sidebarBackground: 'vue'
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  watch: {
    '$store.getters.user': {
      handler: function (user) {
        this.user = user;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.chart-area {
  display: flex;
  justify-content: center;
}
</style>
