<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0" position="right">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="User image"
                  class="circle-img"
                  src="img/theme/user.jpg"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold" :src="user_data">{{
                  user_data
                }}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Benvenuto!</h6>
          </div>
          <div class="dropdown-divider"></div>
          <div
            class="dropdown-item"
            style="cursor: pointer"
            v-on:click="signOut"
          >
            <i class="ni ni-user-run"></i> <span>Logout</span>
          </div>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { BASE_URL } from '../api/index';

export default {
  data() {
    return {
      user_data: '',
      activeNotifications: false,
      showMenu: false,
      searchQuery: ''
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    getUser() {
      this.$axios.get(BASE_URL + '/v1/users/me').then((res) => {
        this.user_data = res.data.username;
      });
    },
    signOut() {
      this.$store.dispatch('logoutUser');
      this.$axios.defaults.headers.common['Authorization'] = '';
      this.$router.push({ path: '/login' });
    }
  },
  watch: {
    '$store.getters.user': {
      handler: function (user) {
        this.user_data = user;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
