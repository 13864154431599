<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <img src="img/theme/logo.png" alt="SicurIA" class="logo" />
          </div>
          <form role="form" v-on:submit.prevent="loginForm">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Username"
              addon-left-icon="ni ni-email-83"
              v-model="username"
              :required="true"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
              :required="true"
            >
            </base-input>
            <div class="text-center">
              <base-button type="primary" nativeType="submit" class="my-4" :disabled="loading">
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <template v-else>Accedi</template>
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getUser } from '../api/index';

export default {
  name: 'login',
  data() {
    return {
      loading: false,
      username: '',
      password: ''
    };
  },
  methods: {
    async loginForm() {
      this.loading = true;
      this.$axios.defaults.headers.common['Authorization'] = '';
      await login(this.username, this.password)
        .then(async (token) => {
          this.$axios.defaults.headers.common['Authorization'] = 'Token ' + token;
          await getUser().then((user) => {
            this.$store.dispatch('loginUser', { user, token });
            this.$router.push({ path: '/dashboard' });
            this.loading = false
          });
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        });
    }
  }
};
</script>
<style scoped>
.logo {
  width: 65%;
  margin: 1.5rem 0;
}
</style>
