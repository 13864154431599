import axios from 'axios';

export const BASE_URL = process.env.VUE_APP_BASE_URL;

export function login(username, password) {
    const formData = {
        username: username,
        password: password
    };
    return axios.post(BASE_URL + '/v1/token/login/', formData)
        .then((res) => res.data.auth_token)
        .catch((err) => { throw err })
        .finally(() => {});
}

export function getUser() {
    return axios.get(BASE_URL + '/v1/users/me')
        .then((res) => res.data.username)
        .catch((err) => { throw err })
        .finally(() => {});
}
