import { Chart, registerables } from 'chart.js';
import Gauge from 'chartjs-gauge';
Chart.register(...registerables);

export const emptyChart = {
  createChart(ctx) {
    if (!ctx) return;
    return new Chart(ctx, {
      type: 'line',
      data: {},
      plugins: [
        {
          id: 'emptyChart',
          afterDraw(chart, args, options) {
            const {
              chartArea: { left, top, right, bottom },
              ctx
            } = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(
              'Nessun dato da mostrare per la ricerca effettuata',
              centerX,
              centerY
            );
            ctx.restore();
          }
        }
      ]
    });
  }
};

export const gauge = {
  createChart(chartId, data) {
    const ctx = document.getElementById(chartId)?.getContext('2d');
    if (!ctx) return;
    if (!data) return emptyChart.createChart(ctx);
    return new Gauge(ctx, {
      type: 'gauge',
      data: {
        datasets: [
          {
            value: data,
            minValue: -1,
            data: [-0.6, -0.2, 0.2, 0.6, 1],
            backgroundColor: ['red', 'orange', 'yellow', 'lightgreen', 'green']
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: 80,
          color: 'rgba(0, 0, 0, 1)'
        },
        valueLabel: {
          display: true,
          formatter: (value) => value,
          color: 'rgba(255, 255, 255, 1)',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          borderRadius: 5,
          fontSize: 14,
          padding: {
            top: 10,
            bottom: 10
          }
        },
        plugins: {
          emptyChart
        }
      }
    });
  }
};

export const line = {
  createChart(chartId, data, tooltip) {
    const ctx = document.getElementById(chartId)?.getContext('2d');
    if (!ctx) return;
    if (!data) return emptyChart.createChart(ctx);
    return new Chart(ctx, {
      type: 'line',
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            label: 'Totale',
            tension: 0.4,
            borderWidth: 4,
            borderColor: '#4884dd',
            pointRadius: 0,
            backgroundColor: 'transparent',
            data: Object.values(data)
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'x'
        },
        layout: {
          padding: 0
        },
        plugins: {
          emptyChart,
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              footer: (tooltipItems) => {
                if (!tooltip) return null;
                const dataset = tooltipItems[0];
                const events = tooltip[dataset.label];
                let t = '';
                if (events) {
                  Object.entries(events).forEach(
                    ([name, value]) => (t += '\n' + name + ': ' + value)
                  );
                }
                return t;
              }
            }
          }
        },
        scales: {
          y: {
            id: 'y',
            type: 'linear',
            position: 'left',
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }
};

export const band = {
  createChart(chartId, data) {
    const ctx = document.getElementById(chartId)?.getContext('2d');
    if (!ctx) return;
    if (!data) return emptyChart.createChart(ctx);
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Object.keys(data.lower),
        datasets: [
          {
            label: 'Medio',
            type: 'line',
            backgroundColor: 'rgb(75, 192, 192, 0.5)',
            borderColor: 'rgb(75, 192, 192)',
            hoverBorderColor: 'rgb(175, 192, 192)',
            fill: false,
            tension: 0,
            data: Object.values(data.median),
            yAxisID: 'y',
            xAxisID: 'x'
          },
          {
            label: 'Migliore',
            type: 'line',
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            pointRadius: 0,
            fill: 0,
            tension: 0,
            data: Object.values(data.upper),
            yAxisID: 'y',
            xAxisID: 'x'
          },
          {
            label: 'Peggiore',
            type: 'line',
            backgroundColor: 'rgb(75, 192, 255, 0.5)',
            borderColor: 'transparent',
            pointRadius: 0,
            fill: 0,
            tension: 0,
            data: Object.values(data.lower),
            yAxisID: 'y',
            xAxisID: 'x'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'x'
        },
        layout: {
          padding: 0
        },
        plugins: {
          emptyChart,
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            id: 'x',
            type: 'category'
          },
          y: {
            id: 'y',
            type: 'linear',
            position: 'left',
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }
};

export const bar = {
  createChart(chartId, data, index) {
    const ctx = document.getElementById(chartId)?.getContext('2d');
    if (!ctx) return;
    if (!data) return emptyChart.createChart(ctx);
    let dataFiltered = {};
    if (index === 0) dataFiltered = data.SentimentCC;
    else if (index === 1) dataFiltered = data.SentimentVersus;
    if (!dataFiltered) return emptyChart.createChart(ctx);
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Object.keys(dataFiltered),
        datasets: [
          {
            label: 'Val',
            tension: 0.4,
            borderWidth: 4,
            borderColor: '#4884dd',
            pointRadius: 0,
            backgroundColor: 'transparent',
            data: Object.values(dataFiltered)
          }
        ]
      },
      options: {
        indexAxis: 'y',
        scale: { x: { min: -1, max: 1 } },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'y'
        },
        layout: {
          padding: 0
        },
        plugins: {
          emptyChart,
          legend: {
            display: false
          }
        }
      }
    });
  }
};
