import { createStore } from 'vuex';
import data from './data';

export default createStore({
  state: {
    user: null,
    token: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
    },
  },
  actions: {
    loginUser(context, { user, token }) {
      context.commit('setUser', user)
      context.commit('setToken', token)
    },
    logoutUser(context) {
      context.commit('setUser', null)
      context.commit('setToken', null)
    }
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token
  },
  modules: {
    data
  }
});
